<template>
	<div class="app-container" v-loading="loading">
		<div class="titlebox">
			<el-button size="medium" type="text" @click="$router.go(-1)">返回</el-button>
			<div class="title">{{ _returnTitle() }}</div>
		</div>
		<el-form ref="form" :rules="rules" :model="form" label-width="150px">
			<section class="sections-container">
				<div class="section-wrap">
					<h3 class="title-icon">基本信息</h3>
					<div v-if="!readonly && _returnEditStatus(1)">
						<el-row>
							<el-col :span="12">
								<el-form-item prop="performanceName" label="绩效考核名称">
									<el-input maxlength="20" style="width: 400px" v-model="form.performanceName" placeholder="输入绩效考核名" />
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item prop="assessCycle" label="考核周期">
									<el-radio-group v-model="form.assessCycle">
										<el-radio label="1">月</el-radio>
										<el-radio label="2">季</el-radio>
										<el-radio label="3">半年</el-radio>
										<el-radio label="4">年</el-radio>
									</el-radio-group>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item prop="assessYear" label="考核年份">
									<el-date-picker
										style="width: 400px"
										v-model="form.assessYear"
										type="year"
										value-format="yyyy"
										format="yyyy"
										:picker-options="pickerOptionsStart"
									>
									</el-date-picker>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item prop="assessStartTime" label="考核时间">
									<el-date-picker
										style="width: 400px"
										v-model="dateRange"
										type="daterange"
										range-separator="至"
										start-placeholder="开始日期"
										value-format="yyyy-MM-dd"
										format="yyyy-MM-dd"
										@change="_dateRangeChange"
										:picker-options="pickerOptionsStart"
										end-placeholder="结束日期"
									>
									</el-date-picker>
								</el-form-item>
							</el-col>
						</el-row>
						<el-form-item prop="assessTableTemplateId" label="考核表模板">
							<el-select style="width: 400px" v-model="form.assessTableTemplateId" placeholder="选择考核表模板">
								<el-option v-for="item in assessTableTemplateList" :key="item.id" :label="item.assessTemplateName" :value="item.id">
								</el-option>
							</el-select>
							<el-tooltip class="item" effect="dark" content="在考核表模板处可编辑模板" placement="right">
								<el-button
									icon="el-icon-warning-outline"
									type="text"
									style="font-size: 16px; margin-left: 20px; color: #ff604d"
								></el-button>
							</el-tooltip>
						</el-form-item>
					</div>
					<el-row :gutter="20" v-else class="showForm">
						<el-col :span="12">
							<span class="showFormTitle">绩效考核名称</span>
							<span>{{ showForm.performanceName }}</span>
						</el-col>
						<el-col :span="12">
							<span class="showFormTitle">考核周期</span>
							<span>{{ assessCycle[showForm.assessCycle] }}</span>
						</el-col>
						<el-col :span="12">
							<span class="showFormTitle">考核时间</span>
							<span
								>{{ showForm.assessStartTime && showForm.assessStartTime.replace(/-/g, '.') }}——{{
									showForm.assessEndTime && showForm.assessEndTime.replace(/-/g, '.')
								}}</span
							>
						</el-col>
						<el-col :span="12">
							<span class="showFormTitle">考核表模板</span>
							<span>{{ showForm.assessTableTemplateName }}</span>
						</el-col>
						<el-col :span="12">
							<span class="showFormTitle">考核年份</span>
							<span>{{ showForm.assessYear }}</span>
						</el-col>
					</el-row>
				</div>
			</section>
			<section class="sections-container">
				<div class="section-wrap">
					<h3 class="title-icon">考核信息</h3>
					<div v-if="_returnEditStatus() || !readonly">
						<el-row :gutter="20">
							<el-col :span="12">
								<el-form-item label="考核目标填写人">
									<el-input style="width: 400px" value="本人" disabled />
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="考核计划确认人">
									<el-input style="width: 400px" value="汇报对象" disabled />
								</el-form-item>
							</el-col>
						</el-row>
						<el-form-item label="考核自评评定人">
							<el-input style="width: 400px" value="汇报对象" disabled />
						</el-form-item>
						<el-form-item prop="assessResultConfirmId" label="考核结果确认人">
							<el-row v-if="_returnEditStatus() && !readonly">
								<el-select v-model="form.assessResultConfirmId" clearable filterable placeholder="请选择确认人" style="width: 400px">
									<el-option v-for="item in staffsList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
								</el-select>
								<el-tooltip class="item" effect="dark" content="结果确认人将整个绩效方案确认" placement="right">
									<el-button
										icon="el-icon-warning-outline"
										type="text"
										style="font-size: 16px; margin-left: 20px; color: #ff604d"
									></el-button>
								</el-tooltip>
							</el-row>
							<!-- <el-tag
                            v-show="_returnEditStatus() && !readonly"
                            class="tag-gap"
                            closable
                            @close="_removeStaff(i)"
                            v-for="(tag,i) in selectedStaffs"
                            :key="i">{{tag.name}}</el-tag>
                        <el-row v-if="_returnEditStatus() && !readonly">
                            <el-col :span="2">
                                <Departments
                                    btnText="添加确认人"
                                    @updateStaffTag="_addApprovalStaffs"
                                    :show-checkbox="false"
                                    :show-staff="true"/>
                            </el-col>
                            <el-col :span="2">
                                <el-tooltip class="item" effect="dark" content="结果确认人将整个绩效方案确认" placement="right">
                                    <el-button icon="el-icon-warning-outline" type="text" style="font-size: 16px;margin-left: 20px;color: #FF604D;"></el-button>
                                </el-tooltip>
                            </el-col>
                        </el-row> -->
							<span v-else>{{ showForm.assessResultConfirmName }}</span>
						</el-form-item>
					</div>
					<el-row :gutter="20" v-else class="showForm">
						<el-col :span="12">
							<span class="showFormTitle">考核目标填写人</span>
							<span>本人</span>
						</el-col>
						<el-col :span="12">
							<span class="showFormTitle">考核计划确认人</span>
							<span>汇报对象</span>
						</el-col>
						<el-col :span="12">
							<span class="showFormTitle">考评自评评定人</span>
							<span>汇报对象</span>
						</el-col>
						<el-col :span="12">
							<span class="showFormTitle">考核结果确认人</span>
							<span>{{ showForm.assessResultConfirmName }}</span>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="员工绩效等级">
								<Etcbox
									:type="2"
									:isInfo="!readonly && _returnEditStatus(1) ? false : true"
									:defaultJobEtcList="readonly ? showForm.staffLevelList : form.staffLevelList"
									:addBtnString="'添加员工考核等级'"
									:numberRemove="3"
									@addDefaultEtc="_addDefaultEtc(2)"
									@delDefalut="_delDefalutStaff"
									@mixDefaultEtc="_mixDefautStaff"
								/>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="组织绩效等级">
								<Etcbox
									:type="1"
									:isInfo="!readonly && _returnEditStatus(1) ? false : true"
									:showRatio="true"
									:defaultJobEtcList="readonly ? showForm.orgLevelList : form.orgLevelList"
									:addBtnString="'添加组织考核等级'"
									:numberRemove="3"
									@addDefaultEtc="_addDefaultEtc(1)"
									@delDefalut="_delDefalutOrg"
									@mixDefaultEtc="_mixDefautOrg"
								/>
							</el-form-item>
						</el-col>
					</el-row>
					<el-form-item label="绩效等级占比">
						<el-row class="propList">
							<el-col
								:span="i % 4 == 0 ? 5 : 6"
								v-for="(item, i) in readonly ? showForm.performanceLevelRatioList : form.performanceLevelRatioList"
								:key="i"
								class="propListCol"
							>
								<div class="propListColTitle">组织绩效：{{ item.levelType }}</div>
								<div v-for="(child, index) in item.levelRatioList" :key="index" class="propListColItem">
									<span style="display: inline-block; width: 40px; text-align: right">{{ child.performanceLevelUser }}</span>
									<span style="margin: 0 10px">:</span>
									<el-input
										:disabled="!readonly && _returnEditStatus(1) ? false : true"
										v-model="child.performanceLevelRatio"
										style="width: 110px"
									>
										<template slot="append">%</template>
									</el-input>
								</div>
							</el-col>
						</el-row>
					</el-form-item>
					<div v-if="!readonly && _returnEditStatus(1)">
						<el-form-item label="考核范围">
							<el-checkbox-group v-model="positiveType" :disabled="recruiterType.length > 0">
								<el-checkbox
									v-for="(item, i) in staffStatusList"
									v-show="item.dictId != 3"
									:key="i"
									:value="item.dictId"
									:label="item.dictId"
									name="positiveType"
									>{{ item.dictName }}</el-checkbox
								>
							</el-checkbox-group>
						</el-form-item>
						<el-form-item>
							<el-checkbox-group v-model="recruiterType" :disabled="positiveType.length > 0">
								<el-checkbox
									v-for="(item, i) in hireFormList"
									:key="i"
									:value="item.dictId"
									:label="item.dictId"
									name="recruiterType"
									>{{ item.dictName }}</el-checkbox
								>
							</el-checkbox-group>
							<el-tag
								style="margin-right: 10px; margin-top: 10px; margin-bottom: 10px"
								closable
								@close="delOrg(orgIndex)"
								v-for="(org, orgIndex) in orgNameList"
								:key="org.id"
								>{{ org }}</el-tag
							>
							<el-tag
								style="margin-right: 10px; margin-top: 10px; margin-bottom: 10px"
								class="tag-gap"
								closable
								@close="_removeStaff2(i)"
								v-for="(tag, i) in selectedStaffs2"
								:key="tag.id"
								>{{ tag.name }}</el-tag
							>
							<el-row>
								<el-col :span="2">
									<Departments
										btnText="添加部门"
										:setChecks="form.orgIds"
										:checkStrictly="false"
										@updateTag="updateDept"
										:showStaff="false"
									/>
								</el-col>
								<el-col :span="2">
									<Departments btnText="添加员工" @updateStaffTag="_addApprovalStaffs2" :show-checkbox="false" :show-staff="true" />
								</el-col>
							</el-row>
							<el-row style="font-size: 14px; color: #c8c8c8">温馨提示：若部门或员工不选，则默认所有部门和员工</el-row>
						</el-form-item>
						<el-form-item label="其他筛选">
							<el-date-picker
								type="date"
								value-format="yyyy-MM-dd"
								format="yyyy-MM-dd"
								v-model="form.entryDate"
								:picker-options="pickerOptionsStart2"
								placeholder="选择日期时间"
							>
							</el-date-picker>
							<span style="margin-left: 10px">之后入职的员工不参加考核（包含当天）</span>
						</el-form-item>
						<el-form-item label="考评分布">
							<el-switch :active-value="1" :inactive-value="0" v-model="form.assessDistributed"></el-switch>
							<span style="margin-left: 10px">强制分布</span>
							<el-tooltip class="item" effect="dark" placement="right">
								<div slot="content">
									1. 开启强制分布：结果确认人确认时，超过比例也能确认通过，绩效评定提交时给予超过提示<br />2.
									不开启强制分布：结果确认人确认时，超过比例也能确认通过
								</div>
								<el-button
									icon="el-icon-warning-outline"
									type="text"
									style="font-size: 16px; margin-left: 20px; color: #ff604d"
								></el-button>
							</el-tooltip>
						</el-form-item>
						<el-form-item label="开启计划">
							<el-switch :active-value="1" :inactive-value="0 || 2" v-model="form.startStatus"></el-switch>
						</el-form-item>
						<el-form-item label="自评状态">
							<el-switch :active-value="1" :inactive-value="0 || 2" v-model="form.selfEvaluationStatus"></el-switch>
						</el-form-item>
						<el-form-item prop="dataStatus" label="状态">
							<el-radio-group v-model="dataStatus">
								<el-radio label="0">草稿</el-radio>
								<el-radio label="1">发布</el-radio>
							</el-radio-group>
						</el-form-item>
					</div>
					<div v-else class="showForm">
						<el-row>
							<el-form-item label="考核范围">
								<el-checkbox-group v-model="showForm.positiveType" disabled>
									<el-checkbox
										v-for="(item, i) in staffStatusList"
										v-show="item.dictId != 3"
										:key="i"
										:value="item.dictId"
										:label="item.dictId"
										name="positiveType"
										>{{ item.dictName }}</el-checkbox
									>
								</el-checkbox-group>
								<el-checkbox-group v-model="showForm.recruiterType" disabled>
									<el-checkbox
										v-for="(item, i) in hireFormList"
										:key="i"
										:value="item.dictId"
										:label="item.dictId"
										name="recruiterType"
										>{{ item.dictName }}</el-checkbox
									>
								</el-checkbox-group>
								<p>{{ _returnArrString() }}</p>
							</el-form-item>
						</el-row>
						<el-row>
							<span class="showFormTitle">评价范围</span>
							<span v-show="showForm.entryDate">{{ `${showForm.entryDate}后` }}</span>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="8">
								<span class="showFormTitle">考评分布</span>
								<el-switch :active-value="1" :inactive-value="0" v-model="showForm.assessDistributed" disabled></el-switch>
							</el-col>
							<el-col :span="8">
								<span class="showFormTitle">开启计划</span>
								<el-switch :active-value="1" :inactive-value="2 || 0" v-model="showForm.startStatus" disabled></el-switch>
							</el-col>
							<el-col :span="8">
								<span class="showFormTitle">自评状态</span>
								<el-switch :active-value="1" :inactive-value="2 || 0" v-model="showForm.selfEvaluationStatus" disabled></el-switch>
							</el-col>
						</el-row>
						<el-row>
							<span class="showFormTitle">状态</span>
							<span>{{ showForm.dataStatus == 0 ? '草稿' : '发布' }}</span>
						</el-row>
					</div>
				</div>
				<el-row v-if="!readonly" style="text-align: center">
					<el-button @click="$router.go(-1)">取消</el-button>
					<el-button type="primary" @click="_create">保存</el-button>
				</el-row>
			</section>
		</el-form>
	</div>
</template>
<script>
import Etcbox from './components/EtcBox';
import Departments from '@/components/departs';
import {
	performanceAdd,
	performanceDetail,
	performanceUpdate,
	performanceTableTemplateList,
	userAdminStaffList,
	performanceAssessSwitch
} from '@/api/performance';
export default {
	components: { Etcbox, Departments },
	data() {
		return {
			assessmentid: this.$route.query.assessmentid,
			readonly: this.$route.query.readonly,
			assessCycle: {
				1: '月',
				2: '季',
				3: '半年',
				4: '年'
			},
			form: {
				performanceName: '',
				assessCycle: '1',
				assessStartTime: '',
				assessEndTime: '',
				assessTableTemplateId: '',
				assessResultConfirmId: '',
				recruiterType: '',
				positiveType: '',
				entryDate: '',
				processStatus: 0,
				assessYear: '',
				assessDistributed: 1,
				startStatus: 0,
				selfEvaluationStatus: 0,
				dataStatus: '0',
				staffIds: [],
				orgIds: [],
				staffLevelList: [
					{ performanceLevelUser: 'S', performanceLevelScoreMin: 90, performanceLevelScoreMax: 100 },
					{ performanceLevelUser: 'A', performanceLevelScoreMin: 80, performanceLevelScoreMax: 89 },
					{ performanceLevelUser: 'B', performanceLevelScoreMin: 70, performanceLevelScoreMax: 79 },
					{ performanceLevelUser: 'C', performanceLevelScoreMin: 60, performanceLevelScoreMax: 69 },
					{ performanceLevelUser: 'D', performanceLevelScoreMin: 0, performanceLevelScoreMax: 59 }
				],
				orgLevelList: [
					{ performanceLevelOrg: '高', performanceLevelRatio: 30, performanceLevelScoreMin: 81, performanceLevelScoreMax: 100 },
					{ performanceLevelOrg: '中', performanceLevelRatio: 50, performanceLevelScoreMin: 60, performanceLevelScoreMax: 80 },
					{ performanceLevelOrg: '低', performanceLevelRatio: 20, performanceLevelScoreMin: 0, performanceLevelScoreMax: 59 }
				],
				performanceLevelRatioList: []
			},
			rules: {
				performanceName: [{ required: true, message: '请输入绩效考核名', trigger: 'blur' }],
				assessCycle: [{ required: true, message: '请选择考核周期', trigger: 'blur' }],
				assessYear: [{ required: true, message: '请选择考核年份', trigger: 'blur' }],
				assessStartTime: [{ required: true, message: '请选择考核时间', trigger: 'blur' }],
				assessTableTemplateId: [{ required: true, message: '请选择考核模板', trigger: 'change' }],
				assessResultConfirmId: [{ required: true, message: '请选择考核结果确认人', trigger: 'change' }],
				recruiterType: [{ required: true, message: '请选择考核范围', trigger: 'blur' }],
				positiveType: [{ required: true, message: '请选择考核范围', trigger: 'blur' }],
				dataStatus: [{ required: true, message: '请选择数据状态 ', trigger: 'change' }]
			},
			showForm: {},
			dateRange: '',
			dataStatus: '0',
			assessTableTemplateList: [],
			staffStatusList: [],
			hireFormList: [],
			selectedStaffs: [],
			selectedStaffs2: [],
			orgNameList: [],
			staffLevelList: [],
			staffLevelListShowLength: 5,
			orgLevelList: [],
			orgLevelListShowLength: 3,
			positiveType: [],
			recruiterType: [],
			staffsList: [],
			loading: false,
			performanceLevelRatioInit: [
				[15, 20, 40, 20, 5],
				[10, 20, 40, 20, 10],
				[5, 20, 40, 20, 15]
			],
			pickerOptionsStart: {
				disabledDate(time) {
					return time.getTime() < new Date(new Date().toLocaleDateString()).getTime();
				}
			},
			pickerOptionsStart2: {
				disabledDate(time) {
					return time.getTime() > new Date(new Date().toLocaleDateString()).getTime();
				}
			}
		};
	},
	mounted() {
		this._getList();
		this.assessmentid ? this._getDetail() : this._mix();
		const mapArr = ['staffStatus', 'hireForm'];

		mapArr.forEach((val) => {
			this[`${val}List`] = this.$store.state.app.dict.filter((item) => item.groupId == val);
		});
	},
	methods: {
		_dateRangeChange(val) {
			this.form.assessStartTime = val ? val[0] : '';
			this.form.assessEndTime = val ? val[1] : '';
		},
		_addApprovalStaffs(staffs) {
			this.form.assessResultConfirmId = staffs[0].id;
			this.selectedStaffs = [staffs[0]];
		},
		_addApprovalStaffs2(staffs) {
			if (this.selectedStaffs2.length > 0) {
				for (const key in staffs) {
					const _obj = staffs[key];

					let _status = false;

					for (const keys in this.selectedStaffs2) {
						if (this.selectedStaffs2[keys].id == _obj.id) {
							_status = true;
						}
					}
					if (!_status) {
						this.selectedStaffs2.push(staffs[key]);
					}
				}
			} else {
				this.selectedStaffs2 = this.selectedStaffs2.concat(staffs);
			}
		},
		_removeStaff() {
			this.selectedStaffs = this.form.assessResultConfirmId = '';
		},
		_removeStaff2(index) {
			this.selectedStaffs2.splice(index, 1);
		},
		_getList() {
			performanceTableTemplateList({}).then((res) => {
				this.assessTableTemplateList = res.list;
			});
			userAdminStaffList({}).then((res) => {
				this.staffsList = res;
			});
		},
		_getDetail() {
			performanceDetail({ id: this.assessmentid }).then((res) => {
				this.form = res;
				this.showForm = res;
				this.selectedStaffs = res.assessResultConfirmName ? [{ name: res.assessResultConfirmName }] : [];
				this.staffLevelListShowLength = res.staffLevelList ? res.staffLevelList.length : 5;
				this.orgLevelListShowLength = res.orgLevelList ? res.orgLevelList.length : 3;
				for (const key in res.staffIds) {
					this.selectedStaffs2.push({ name: res.staffNames[key], id: res.staffIds[key] });
				}
				for (const key in res.orgIds) {
					this.orgNameList.push(res.orgNames[key]);
				}
				this.positiveType = this.form.positiveType = res.positiveType ? res.positiveType.split(',') : [];
				this.recruiterType = this.form.recruiterType = res.recruiterType ? res.recruiterType.split(',') : [];
				this.form.assessCycle = String(res.assessCycle);
				this.form.dataStatus = String(res.dataStatus);
				this.dataStatus = String(res.dataStatus);
				this.form.assessResultConfirmId = res.assessResultConfirmId == 0 ? '' : String(res.assessResultConfirmId);
				this.form.assessTableTemplateId = res.assessTableTemplateId == 0 ? '' : String(res.assessTableTemplateId);
				this.dateRange = [res.assessStartTime, res.assessEndTime];
				if (this.$route.query.copy) {
					this.form.id = this.form.performanceName = this.form.assessStartTime = this.form.assessEndTime = this.dateRange = '';
				}
				// this._mix();
			});
		},
		// _getLevelList(){
		//     performanceScoreList({screenType: 1}).then(async res => {
		//         this.orgLevelList = res.list;
		//         if(!this.assessmentid){
		//             let _list = [];
		//             for(let i = 0;i<this.orgLevelListShowLength;i++){
		//                 _list.push({performanceLevelOrg : this.orgLevelList[i].value});
		//             }
		//             this.form.orgLevelList = _list;
		//             this._mix();
		//         }
		//     })
		//     performanceScoreList({screenType: 2}).then(res => {
		//         this.staffLevelList = res.list;
		//         if(!this.assessmentid){
		//             let _list = [];
		//             for(let i = 0;i<this.staffLevelListShowLength;i++){
		//                 _list.push({performanceLevelUser : this.staffLevelList[i].value});
		//             }
		//             this.form.staffLevelList = _list;
		//             this._mix();
		//         }
		//     })
		// },
		_addDefaultEtc(type) {
			if (type == 1) {
				this.orgLevelListShowLength += 1;
				this.form.orgLevelList.push({ performanceLevelOrg: '' });
				const _obj = { levelType: '', levelRatioList: [] };

				for (const s in this.form.staffLevelList) {
					_obj.levelRatioList.push({ performanceLevelUser: this.form.staffLevelList[s].performanceLevelUser });
				}
				this.form.performanceLevelRatioList.push(_obj);
			} else {
				this.staffLevelListShowLength += 1;
				this.form.staffLevelList.push({ performanceLevelUser: '' });
				for (const key in this.form.performanceLevelRatioList) {
					this.form.performanceLevelRatioList[key].levelRatioList.push({ performanceLevelUser: '' });
				}
			}
		},
		_delDefalutStaff(index) {
			this.staffLevelListShowLength -= 1;
			this.form.staffLevelList.splice(index, 1);
			for (const key in this.form.performanceLevelRatioList) {
				this.form.performanceLevelRatioList[key].levelRatioList.splice(index, 1);
			}
		},
		_delDefalutOrg(index) {
			this.orgLevelListShowLength -= 1;
			this.form.orgLevelList.splice(index, 1);
			this.form.performanceLevelRatioList.splice(index, 1);
		},
		_mixDefautStaff(obj) {
			for (const key in this.form.performanceLevelRatioList) {
				this.form.performanceLevelRatioList[key].levelRatioList[obj.index].performanceLevelUser = obj.val;
			}
		},
		_mixDefautOrg(obj) {
			this.form.performanceLevelRatioList[obj.index].levelType = obj.val;
		},
		_mix() {
			const _list = [];

			for (const key in this.form.orgLevelList) {
				const _obj = { levelType: '', levelRatioList: [] };

				_obj.levelType = this.form.orgLevelList[key].performanceLevelOrg;
				for (const s in this.form.staffLevelList) {
					_obj.levelRatioList.push({
						performanceLevelUser: this.form.staffLevelList[s].performanceLevelUser,
						performanceLevelRatio: this.performanceLevelRatioInit[key][s]
					});
				}
				_list.push(_obj);
			}
			this.form.performanceLevelRatioList = _list;
		},
		_create() {
			if (this.form.processStatus == 2 && !this.$route.query.copy) {
				if (!this.form.assessResultConfirmId) {
					this.$message.error('请选择考核结果确认人');
				} else {
					this.loading = true;
					const _param = {
						performanceId: this.form.id,
						switchType: 4,
						assessResultConfirmId: this.form.assessResultConfirmId
					};

					performanceAssessSwitch(_param).then((res) => {
						this.loading = false;
						if (res.code == 0) {
							this.$message.success('发布成功');
							this.$router.go(-1);
						}
					});
				}
				return false;
			}
			this.form.positiveType = this.positiveType.join(',');
			this.form.recruiterType = this.recruiterType.join(',');
			if (this.dataStatus == 1) {
				if (
					!(
						(this.form.positiveType == '' && this.form.recruiterType != '') ||
						(this.form.positiveType != '' && this.form.recruiterType == '')
					)
				) {
					this.$message.error('请在考核范围中选择转正状态或聘用形式');
					return false;
				}
			}
			const _list = [];

			for (const key in this.selectedStaffs2) {
				_list.push(this.selectedStaffs2[key].id);
			}
			this.form.staffIds = _list;
			// this.form.dataStatus = Number(this.dataStatus);
			this.form.startStatus = this.form.startStatus == 2 ? 0 : this.form.startStatus;
			this.form.selfEvaluationStatus = this.form.selfEvaluationStatus == 2 ? 0 : this.form.selfEvaluationStatus;
			const _form = JSON.parse(JSON.stringify(this.form));

			_form.dataStatus = Number(this.dataStatus);
			const _performanceLevelRatioList = [];

			const _orgSet = [];

			let _orgScore = 0;

			if (this.dataStatus == 1) {
				if (
					this.form.orgLevelList[0].performanceLevelScoreMax != 100 ||
					this.form.orgLevelList[this.form.orgLevelList.length - 1].performanceLevelScoreMin != 0
				) {
					this.$message.error('组织分数区间为0-100');
					return false;
				}
				if (
					this.form.staffLevelList[0].performanceLevelScoreMax != 100 ||
					this.form.staffLevelList[this.form.staffLevelList.length - 1].performanceLevelScoreMin != 0
				) {
					this.$message.error('员工分数区间为0-100');
					return false;
				}
				for (const key in this.form.orgLevelList) {
					_orgSet.push(this.form.orgLevelList[key].performanceLevelOrg);
					if (this.form.orgLevelList[key].performanceLevelScoreMin >= this.form.orgLevelList[key].performanceLevelScoreMax) {
						this.$message.error('组织分数区间不可重叠');
						return false;
					}
					if (
						key != 0 &&
						this.form.orgLevelList[key].performanceLevelScoreMax >= this.form.orgLevelList[Number(key) - 1].performanceLevelScoreMin
					) {
						this.$message.error('组织分数区间不可重叠');
						return false;
					}
					if (
						key != 0 &&
						key != this.form.orgLevelList.length - 1 &&
						this.form.orgLevelList[key].performanceLevelScoreMax != this.form.orgLevelList[Number(key) - 1].performanceLevelScoreMin - 1
					) {
						this.$message.error('组织分数区间需要闭合(包含0-100)');
						return false;
					}
					if (key == this.form.orgLevelList.length - 1) {
						if (
							this.form.orgLevelList[key].performanceLevelScoreMax !=
								this.form.orgLevelList[Number(key) - 1].performanceLevelScoreMin - 1 ||
							this.form.orgLevelList[key].performanceLevelScoreMin != 0
						) {
							this.$message.error('组织分数区间需要闭合(包含0-100)');
							return false;
						}
					}
					if (
						this.form.orgLevelList[key].performanceLevelOrg &&
						this.form.orgLevelList[key].performanceLevelRatio >= 0 &&
						this.form.orgLevelList[key].performanceLevelScoreMin >= 0 &&
						this.form.orgLevelList[key].performanceLevelScoreMax >= 0
					) {
						const _StarffSet = [];

						let _StarffScore = 0;

						for (const keys in this.form.staffLevelList) {
							_StarffSet.push(this.form.staffLevelList[keys].performanceLevelUser);
							if (this.form.staffLevelList[keys].performanceLevelScoreMin >= this.form.staffLevelList[keys].performanceLevelScoreMax) {
								this.$message.error('员工分数区间不可重叠');
								return false;
							}
							if (
								keys != 0 &&
								this.form.staffLevelList[keys].performanceLevelScoreMax >=
									this.form.staffLevelList[Number(keys) - 1].performanceLevelScoreMin
							) {
								this.$message.error('员工分数区间不可重叠');
								return false;
							}
							if (
								keys != 0 &&
								keys != this.form.staffLevelList.length - 1 &&
								this.form.staffLevelList[keys].performanceLevelScoreMax !=
									this.form.staffLevelList[Number(keys) - 1].performanceLevelScoreMin - 1
							) {
								this.$message.error('员工分数区间需要闭合(包含0-100)');
								return false;
							}
							if (keys == this.form.staffLevelList.length - 1) {
								if (
									this.form.staffLevelList[keys].performanceLevelScoreMax !=
										this.form.staffLevelList[Number(keys) - 1].performanceLevelScoreMin - 1 ||
									this.form.staffLevelList[keys].performanceLevelScoreMin != 0
								) {
									this.$message.error('员工分数区间需要闭合(包含0-100)');
									return false;
								}
							}
							if (
								this.form.staffLevelList[keys].performanceLevelUser &&
								this.form.staffLevelList[keys].performanceLevelScoreMin >= 0 &&
								this.form.staffLevelList[keys].performanceLevelScoreMax >= 0
							) {
								if (this.form.performanceLevelRatioList[key].levelRatioList[keys].performanceLevelRatio >= 0) {
									_performanceLevelRatioList.push({
										performanceLevelOrg: this.form.orgLevelList[key].performanceLevelOrg,
										performanceLevelUser: this.form.staffLevelList[keys].performanceLevelUser,
										performanceLevelRatio: this.form.performanceLevelRatioList[key].levelRatioList[keys].performanceLevelRatio,
										performanceLevelScoreMin: this.form.staffLevelList[keys].performanceLevelScoreMin,
										performanceLevelScoreMax: this.form.staffLevelList[keys].performanceLevelScoreMax
									});
								} else {
									this.$message.error('请完绩效等级占比');
									return false;
								}
							} else {
								this.$message.error('请完善员工绩效等级');
								return false;
							}
							_StarffScore += Number(this.form.performanceLevelRatioList[key].levelRatioList[keys].performanceLevelRatio);
						}
						if (_StarffScore != 100) {
							this.$message.error(`组织绩效：${this.form.orgLevelList[key].performanceLevelOrg}绩效等级占比总比要百分之一百`);
							return false;
						}
						if (new Set(_StarffSet).size != _StarffSet.length) {
							this.$message.error('存在相同的员工绩效等级名');
							return false;
						}
					} else {
						this.$message.error('请完善组织绩效等级');
						return false;
					}
					_orgScore += Number(this.form.orgLevelList[key].performanceLevelRatio);
				}
				if (_orgScore != 100) {
					this.$message.error('组织绩效等级总比要百分之百');
					return false;
				}
				if (new Set(_orgSet).size != _orgSet.length) {
					this.$message.error('存在相同的组织绩效等级名');
					return false;
				}
			} else {
				for (const key in this.form.orgLevelList) {
					for (const keys in this.form.staffLevelList) {
						_performanceLevelRatioList.push({
							performanceLevelOrg: this.form.orgLevelList[key].performanceLevelOrg,
							performanceLevelUser: this.form.staffLevelList[keys].performanceLevelUser,
							performanceLevelRatio: this.form.performanceLevelRatioList[key].levelRatioList[keys].performanceLevelRatio,
							performanceLevelScoreMin: this.form.staffLevelList[keys].performanceLevelScoreMin,
							performanceLevelScoreMax: this.form.staffLevelList[keys].performanceLevelScoreMax
						});
					}
				}
			}
			_form.performanceLevelRatioList = _performanceLevelRatioList;
			if (this.assessmentid) {
				if (_form.dataStatus == 1) {
					this.$refs['form'].validate((valid) => {
						if (valid) {
							this.$confirm(`您确认要发布${this.form.performanceName}?`, '提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning'
							})
								.then(() => {
									this.loading = true;
									if (this.$route.query.copy) {
										performanceAdd(_form).then((res) => {
											this.loading = false;
											if (res.code == 0) {
												this.$message.success('发布成功');
												this.$router.go(-1);
											}
										});
									} else {
										performanceUpdate(_form).then((res) => {
											this.loading = false;
											if (res.code == 0) {
												this.$message.success('发布成功');
												this.$router.go(-1);
											}
										});
									}
								})
								.catch(() => {});
						}
					});
				} else if (this.$route.query.copy) {
					if (this.form.performanceName) {
						this.loading = true;
						performanceAdd(_form).then((res) => {
							this.loading = false;
							if (res.code == 0) {
								this.$message.success('发布成功');
								this.$router.go(-1);
							}
						});
					} else {
						this.$message.error('请输入绩效考核名称');
					}
				} else if (this.form.performanceName) {
					this.loading = true;
					performanceUpdate(_form).then((res) => {
						this.loading = false;
						if (res.code == 0) {
							this.$message.success('保存成功');
							this.$router.go(-1);
						}
					});
				} else {
					this.$message.error('请输入绩效考核名称');
				}
			} else if (_form.dataStatus == 1) {
				this.$refs['form'].validate((valid) => {
					if (valid) {
						this.$confirm(`您确认要发布${this.form.performanceName}?`, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						})
							.then(() => {
								this.loading = true;
								performanceAdd(_form).then((res) => {
									this.loading = false;
									if (res.code == 0) {
										this.$message.success('发布成功');
										this.$router.go(-1);
									}
								});
							})
							.catch(() => {});
					}
				});
			} else if (this.form.performanceName) {
				this.loading = true;
				performanceAdd(_form).then((res) => {
					this.loading = false;
					if (res.code == 0) {
						this.$message.success('保存成功');
						this.$router.go(-1);
					}
				});
			} else {
				this.$message.error('请输入绩效考核名称');
			}
		},
		_returnEditStatus(type) {
			if (this.$route.query.copy) {
				return true;
			}
			if (this.form.dataStatus == 0 || this.form.processStatus == 1) {
				return true;
			}
			if (type) {
				return false;
			}
			if (this.form.processStatus < 3) {
				return true;
			}
			return false;
		},
		_returnTitle() {
			if (this.readonly) {
				return '绩效详情';
			}
			if (this.assessmentid && !this.$route.query.copy) {
				return '绩效编辑';
			}
			return '新建绩效';
		},
		_returnArrString() {
			if (this.showForm.orgNames && this.showForm.staffNames) {
				return this.showForm.orgNames.concat(this.showForm.staffNames).join(',');
			}
			if (this.showForm.orgNames && this.showForm.staffNames) {
				return this.showForm.orgNames;
			}
			return this.showForm.staffNames;
		},
		updateDept(data) {
			// this.orgObj = data;
			this.form.orgIds = [];
			this.orgNameList = [];
			for (const org of data) {
				this.form.orgIds.push(org.id);
				this.orgNameList.push(org.orgName);
			}
		},
		delOrg(index) {
			// this.orgObj.splice(index, 1);
			this.form.orgIds.splice(index, 1);
			this.orgNameList.splice(index, 1);
		}
	}
};
</script>
<style lang="scss">
.sections-container {
	border-radius: 4px;
	padding: 27px 30px;
	background-color: #fff;
	overflow: hidden;
	margin-bottom: 20px;
	.title-icon {
		font-size: 16px;
		margin: 0 0 20px 10px;
		&:before {
			display: inline-block;
			width: 8px;
			height: 18px;
			background: #498df0;
			content: ' ';
			border-radius: 5px;
			vertical-align: top;
			margin-right: 5px;
		}
	}
	.section-wrap {
		margin-bottom: 20px;
	}
}
.titlebox {
	text-align: center;
	line-height: 17px;
	background: #fff;
	font: bold 18px/25px 'Microsoft YaHei';
	padding: 14px 40px;
	margin-bottom: 20px;
	> .el-button {
		float: left;
		color: #484848;
		transform: translateY(-8px);
		font-size: 16px;
	}
}
.propList {
	border-bottom: 1px dashed #ebebeb;
	.propListCol {
		border-right: 1px dashed #ebebeb;
		margin-bottom: 20px;
		box-sizing: border-box;
		padding: 0 10px;
		.propListColTitle {
			font-size: 12px;
			color: #777e8c;
		}
		.propListColItem {
			margin-bottom: 10px;
		}
	}
}
.showForm {
	font-size: 14px;
	color: #484848;
	line-height: 50px;
	.showFormTitle {
		font-weight: normal;
		color: #606266;
		width: 150px;
		text-align: right;
		display: inline-block;
		padding: 0 12px 0 0;
	}
}
</style>
<style lang="scss" scoped>
::v-deep.el-form-item__label {
	font-weight: normal;
}
</style>
